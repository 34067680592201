<template>
  <div class="d-flex flex-column flex-grow-1">
    <FilterRegionCountry
      placeholder="Status"
      class="flex-column mt-4"
      @inputRegion="setRegionFilter"
      @inputCountry="setCountryFilter"
      :countriesGroupedByRegions="countriesGroupedByRegions"
      :valueRegion="regionFilter"
      :valueCountry="countryFilter"
    />
    <SearchReiseMultiple
      class="mt-4"
      @reiseSelected="setReisenKuerzelFilter"
      :options="reisenkuerzelFilterOptions"
      :value="reisenkuerzelFilter"
      :placeholder="$t('REISEN.COMMON.REISE')"
    />
    <b-button-group size="sm" class="mt-4 mb-3">
      <b-button
        v-for="prioritaet in prioritaeten"
        :key="prioritaet.value"
        :id="prioritaet.name"
        @click="setPrioritaetFilter"
        :variant="
          prioritaetenSelected.includes(prioritaet.value)
            ? 'primary'
            : prioritaetenSelected.length === 0 && prioritaet.name === 'Alle'
            ? 'primary'
            : ''
        "
        class="mr-1"
        style="width: 33%"
      >
        {{ prioritaet.name }}
      </b-button>
    </b-button-group>
    <b-button-group size="sm" class="mt-4 mb-3">
      <b-button
        v-for="regionsmanager in regionsmanagers"
        :key="regionsmanager"
        :id="regionsmanager"
        @click="setRegionsmanagerFilter"
        :variant="
          regionsmanagersSelected.includes(regionsmanager)
            ? 'primary'
            : regionsmanagersSelected.length === 0 && regionsmanager === 'Alle'
            ? 'primary'
            : ''
        "
        class="mr-1"
        style="width: 33%"
      >
        {{ regionsmanager }}
      </b-button>
    </b-button-group>
    <DateRangePicker
      class="mt-4"
      :placeholder="$t('REISEN.COMMON.STARTDATE')"
      :value="startDateFilter"
      @input="setStartDateFilter"
    />
    <div class="w-100 mt-4 flex-grow-1 flex-column">
      <b-button
        :disabled="isDifferentFilterApplied || isInitiallyLoading"
        lg
        block
        @click="onClickSaveFilter($event)"
        variant="primary"
      >
        {{ $t('COMMON.FILTERANWENDEN') }}
      </b-button>
      <b-button
        block
        variant="danger"
        @click="onClickResetFilter($event)"
        v-show="isAnyFilterApplied"
        v-t="'REISEN.COMMON.RESET'"
      ></b-button>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';

import {
  SAVED_FILTER,
  RESET_FILTER,
  SET_IS_CURRENT_FILTER_APPLIED,
  SET_REISEN_KUERZEL_FILTER,
  GET_REISEN_KUERZEL_FILTER_OPTIONS,
  GET_ALL_COUNTRIES_GROUPED_BY_REGIONS,
  SET_REGION_FILTER,
  SET_COUNTRY_FILTER,
  SET_PRIORITAET_FILTER,
  RESET_PRIORITAET_FILTER,
  SET_REGIONSMANAGER_FILTER,
  RESET_REGIONSMANAGER_FILTER,
  SET_START_DATE_FILTER,
} from '@/core/produkte/reisen/stores/reisen.module';

import SearchReiseMultiple from '@/components/produkte/reisen/search-reise-multiple.vue';
import FilterRegionCountry from '@/components/produkte/reisetermine/filter-region-country.vue';
import DateRangePicker from '@/components/common/date-range-picker.vue';

export default {
  data() {
    return {
      prioritaeten: [
        { name: 'Alle', value: 4 },
        { name: 'A', value: 0 },
        { name: 'B', value: 1 },
        { name: 'C', value: 2 },
        { name: 'D', value: 3 },
      ],
      regionsmanagers: ['Alle', 'NIGO', 'LEBE', 'STGE', 'SYKE', 'NIEX', 'SAKI'],
    };
  },
  components: {
    SearchReiseMultiple,
    FilterRegionCountry,
    DateRangePicker,
  },
  computed: {
    ...mapState({
      isAnyFilterApplied: state => state.reisen.isAnyFilterApplied,
      isInitiallyLoading: state => state.reisen.isInitiallyLoading,
      countriesGroupedByRegions: state => state.reisen.countriesGroupedByRegions,
      isCurrentFilterApplied: state => state.reisen.isCurrentFilterApplied,
      appliedReisen: state => state.reisen.appliedReisenFilter,
      reisenkuerzelFilter: state => state.reisen.filter.reisenkuerzelFilter,
      reisenkuerzelFilterOptions: state => state.reisen.reisenkuerzelFilterOptions,
      regionFilter: state => state.reisen.filter.regionFilter,
      countryFilter: state => state.reisen.filter.countryFilter,
      missingDataFilter: state => state.reisen.filter.missingDataFilter,
      startDateFilter: state => state.reisen.filter.startDateFilter,
      isWithoutKonfigurationFilter: state => state.reisen.filter.isWithoutKonfigurationFilter,
      prioritaetenSelected: state => state.reisen.filter.prioritaetFilter,
      regionsmanagersSelected: state => state.reisen.filter.regionsmanagerFilter,
    }),
    ...mapGetters(['isDifferentFilterApplied']),
  },
  mounted() {
    this.$store.dispatch(GET_REISEN_KUERZEL_FILTER_OPTIONS);

    if (!this.countriesGroupedByRegions) {
      this.$store.dispatch(GET_ALL_COUNTRIES_GROUPED_BY_REGIONS);
    }
  },
  methods: {
    onClickSaveFilter($event) {
      this.$store.dispatch(SAVED_FILTER);
    },
    onClickResetFilter($event) {
      this.$store.dispatch(RESET_FILTER);
    },
    setReisenKuerzelFilter(filter) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_REISEN_KUERZEL_FILTER, filter);
    },
    setRegionFilter(region) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_REGION_FILTER, region);
      // Reisekuerzel filter is based on region and country selection so we have to reload it
      // this.$store.commit(REFRESH_REISEN_KUERZEL_FILTER_OPTIONS);
    },
    setCountryFilter(country) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_COUNTRY_FILTER, country);
      // Reisekuerzel filter is based on region and country selection so we have to reload it
      // this.$store.dispatch(REFRESH_REISEN_KUERZEL_OPTION);
    },
    setStartDateFilter(start, end) {
      this.$store.commit(SET_IS_CURRENT_FILTER_APPLIED, false);
      this.$store.commit(SET_START_DATE_FILTER, {
        start: start || null,
        end: end || null,
      });
    },
    setPrioritaetFilter($event) {
      const { id } = $event.target;
      const prioritaet = this.prioritaeten.find(item => item.name === id).value;
      if (id === 'Alle') {
        this.$store.commit(RESET_PRIORITAET_FILTER);
      } else if (!this.prioritaetenSelected.includes(prioritaet)) {
        this.$store.commit(SET_PRIORITAET_FILTER, [...this.prioritaetenSelected, prioritaet]);
      } else {
        this.$store.commit(
          SET_PRIORITAET_FILTER,
          this.prioritaetenSelected.filter(prioritaetSelected => prioritaetSelected !== prioritaet)
        );
      }

      // Wenn alle angeklickt sind, resette
      if (this.prioritaetenSelected.length === this.prioritaeten.length - 1) {
        this.$store.commit(RESET_PRIORITAET_FILTER);
      }
    },

    setRegionsmanagerFilter($event) {
      const { id: regionsmanager } = $event.target;
      if (regionsmanager === 'Alle') {
        this.$store.commit(RESET_REGIONSMANAGER_FILTER);
      } else if (!this.regionsmanagersSelected.includes(regionsmanager)) {
        this.$store.commit(SET_REGIONSMANAGER_FILTER, [...this.regionsmanagersSelected, regionsmanager]);
      } else {
        this.$store.commit(
          SET_REGIONSMANAGER_FILTER,
          this.regionsmanagersSelected.filter(
            regionsmanagerSelected => regionsmanagerSelected !== regionsmanager
          )
        );
      }

      // Wenn alle angeklickt sind, resette
      if (this.regionsmanagersSelected.length === this.regionsmanagers.length - 1) {
        this.$store.commit(RESET_REGIONSMANAGER_FILTER);
      }
    },
  },
};
</script>
